<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary" v-if="permFilterVendor()">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="row mt-4 create-new">
      <div class="col-md-12">
        <div class="card">
          <div class="bg-success p-3">
            <h5 class="card-title mb-0 font-weight-bold">Bulk Create</h5>
          </div>
          <div class="card-body">
            <b-row align-v="center">
              <b-col lg="6">
                <b-row v-if="permFilterVendor()">
                  <b-form-group label="Vendor" class="col">
                    <multiselect v-model="vendor" label="name" track-by="name" placeholder="Enter Vendor"
                      open-direction="bottom" :options="vendorSearchOptions" :searchable="true"
                      :loading="isVendorSearch" :close-on-select="true" :multiple="false" @search-change="vendorFind">
                    </multiselect>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group class="col">
                    <b-form-file v-model="file_csv" accept=".csv" placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here...">
                    </b-form-file>
                    <b-button @click="reset" variant="link" class="text-danger">
                      Reset
                    </b-button>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <b-link class="btn btn-outline" :href="URL_STATIC.TEMPLATE_BULK_CREATE"
                      style="color: #28a745; border-color: #28a745">
                      Template <i class="fa fa-file-excel-o"></i>
                    </b-link>
                  </b-form-group>
                </b-row>
                <b-row>
                </b-row>
              </b-col>
              <b-col lg="6">
                <li v-for="(item) in statusUpload" :key="item.item_name">
                  <span v-if="item.error">
                    {{ item.item_name }} - {{ item.message }}
                  </span>
                  <span v-else-if="item.is_new">
                    <router-link target="_blank" :to="{ name: DetailItems.name, params: { id: item.data.id } }">
                      {{ item.item_name }}
                    </router-link>
                    - success uploaded
                  </span>
                  <span v-else>
                    <router-link target="_blank" :to="{ name: DetailItems.name, params: { id: item.data.id } }">
                      {{ item.item_name }}
                    </router-link>
                    - item already uploaded
                  </span>
                  <span v-if="item.data">
                    <span v-if="item.data.single">
                      with single offer
                      <router-link target="_blank"
                        :to="{ name: SingleEditPath.name, params: { id: item.data.single[0].id } }">
                        {{ item.data.single[0].name }}
                      </router-link>
                    </span>
                  </span>
                </li>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols='12'>
        <b-overlay :show="isValidate" rounded="lg" opacity="0.6" spinner-variant="primary">
          <div class="card">
            <div class="bg-success p-3">
              <h5 class="card-title mb-0 font-weight-bold">Review Data</h5>
            </div>
            <div class="card-body">
              <b-form @submit="onUpload">
                <b-row>
                  <b-col>
                    <b-button variant="primary" type="submit">
                      Submit
                    </b-button>
                  </b-col>
                  <b-col class="text-right">
                    <b-button variant="primary" @click="handleAdd()">
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-col>
                  <b-col class="p-2" cols="12" v-if="isUpload">
                    <b-progress :value="progressBar" :max="maxProgressBar" animated height="5px"></b-progress>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="p-3" style="overflow: auto;">
                    <div class="table-wrap">
                      <b-button class="badge badge-primary" @click="onBulkCreateAuthor" v-if="isBulkAuthorShow()">create all author</b-button>
                      <b-table show-empty striped hover sticky-header="600px" :items="bulkData" :fields="fields"
                        style="white-space: unset; min-height: 500px;">
                        <template #cell(action)="data">
                          <i class="fa fa-trash fa-2 text text-danger" style="cursor: pointer; font-size: 24px;"
                            @click="handleDelete(data)"></i>
                        </template>
                        <template #cell(name)="data">
                          {{ data.index+1 }}
                          <b-form-textarea v-model="bulkData[data.index].name" style="width:200px">
                          </b-form-textarea>
                        </template>
                        <template #cell(author)="data">
                          <multiselect v-model="bulkData[data.index].author" label="name" track-by="name"
                            placeholder="Enter Author" open-direction="bottom" :options="authorSearchOptions"
                            :loading="isAuthorSearch" :searchable="true" :close-on-select="true" :multiple="true"
                            @search-change="authorFind" style="min-width: 200px;">
                          </multiselect>
                          <b-row v-for="author in authorNotFound[data.index]" :key="author">
                            <b-col cols="12">
                              {{ author }}
                              <b-button class="badge badge-primary" @click="onCreateAuthor(author)">create</b-button>
                            </b-col>
                          </b-row>
                        </template>
                        <template #cell(languages)="data">
                          <multiselect placeholder="Enter languages" open-direction="bottom"
                            v-model="bulkData[data.index].languages" label="name" track-by="name"
                            :custom-label="nameWithISO" :options="languagesSearchOptions" :searchable="true"
                            :close-on-select="true" :multiple="true" style="width: 200px;">
                          </multiselect>
                        </template>
                        <template #cell(countries)="data">
                          <multiselect placeholder="Enter Countries" open-direction="bottom"
                            v-model="bulkData[data.index].countries" label="name" track-by="name"
                            :custom-label="nameWithISO" :options="countriesSearchOptions" :searchable="true"
                            :close-on-select="true" :multiple="true" style="width: 200px;">
                          </multiselect>
                        </template>
                        <template #cell(categories)="data">
                          <multiselect v-model="bulkData[data.index].categories" label="name" track-by="name"
                            placeholder="Enter Categories" open-direction="bottom" :options="categoriesSearchOptions"
                            :loading="isCategoriesSearch" :searchable="true" :close-on-select="true" :multiple="true"
                            @search-change="categoriesFind($event, bulkData[data.index])" style="width: 200px;">
                          </multiselect>
                        </template>
                        <template #cell(content_type)="data">
                          <b-form-select v-model="bulkData[data.index].content_type" :options="contentTypeOptions"
                            style="width:100px">
                          </b-form-select>
                        </template>
                        <template #cell(item_type)="data">
                          <b-form-select v-model="bulkData[data.index].item_type" :options="itemTypeOptions"
                            style="width:100px">
                          </b-form-select>
                        </template>
                        <template #cell(release_date)="data">
                          <b-form-datepicker today-button v-model="bulkData[data.index].release_date"
                            style="width:200px">
                          </b-form-datepicker>
                        </template>
                        <template #cell(release_schedule)="data">
                          <b-form-datepicker today-button v-model="bulkData[data.index].release_schedule"
                            style="width:200px">
                          </b-form-datepicker>
                        </template>
                        <template #cell(description)="data">
                          <b-form-textarea v-model="bulkData[data.index].description" style="width:300px">
                          </b-form-textarea>
                        </template>
                        <template #cell(isbn)="data">
                          <b-form-input v-model="bulkData[data.index].isbn" style="width:200px"></b-form-input>
                        </template>
                        <template #cell(ios_tier)="data">
                          <multiselect v-model="bulkData[data.index].ios_tier" label="tier_code" track-by="tier_code"
                            placeholder="Ios Tier" open-direction="bottom" :options="iosTierSearchOptions"
                            :searchable="true" :loading="isIosTierSearch" :close-on-select="true" :multiple="false"
                            @search-change="iosTierFind" style="min-width: 250px;">
                          </multiselect>
                          <em>
                            IDR : {{ bulkData[data.index].ios_tier ? bulkData[data.index].ios_tier.price_idr : 0 | formatPriceId }}
                          </em>
                        </template>
                        <template #cell(android_tier)="data">
                          <multiselect v-model="bulkData[data.index].android_tier" label="tier_code" track-by="tier_code"
                            placeholder="Android Tier" open-direction="bottom" :options="androidTierSearchOptions"
                            :searchable="true" :loading="isAndroidTierSearch" :close-on-select="true" :multiple="false"
                            @search-change="androidTierFind" style="min-width: 250px;">
                          </multiselect>
                            IDR : {{ bulkData[data.index].android_tier ? bulkData[data.index].android_tier.price_idr : 0 | formatPriceId }}

                        </template>
                        <template #cell(printed_currency)="data">
                          <b-form-select v-model="bulkData[data.index].printed_currency" :options="currencyOptions"
                            style="width:100px">
                          </b-form-select>
                        </template>
                        <template #cell(printed_price)="data">
                          <b-form-input type="number" step="any" v-model="bulkData[data.index].printed_price"
                            style="width:100px">
                          </b-form-input>
                        </template>
                        <template #cell(full_premium)="data">
                          <b-form-checkbox v-model="bulkData[data.index].full_premium" class="text-center">
                          </b-form-checkbox>
                        </template>
                        <template #cell(kids_premium)="data">
                          <b-form-checkbox v-model="bulkData[data.index].kids_premium" class="text-center">
                          </b-form-checkbox>
                        </template>
                        <template #cell(fiction_premium)="data">
                          <b-form-checkbox v-model="bulkData[data.index].fiction_premium" class="text-center">
                          </b-form-checkbox>
                        </template>
                        <template #cell(non_fiction_premium)="data">
                          <b-form-checkbox v-model="bulkData[data.index].non_fiction_premium" class="text-center">
                          </b-form-checkbox>
                        </template>
                        <template #cell(yearly_premium)="data">
                          <b-form-checkbox v-model="bulkData[data.index].yearly_premium" class="text-center">
                          </b-form-checkbox>
                        </template>
                        <template #cell(category_ax)="data">
                          <multiselect v-model="bulkData[data.index].category_ax" label="name" track-by="name"
                            placeholder="Category Ax" open-direction="bottom" :options="axSearchOptions"
                            :searchable="true" :loading="isAxSearch" :close-on-select="true" :multiple="false"
                            @search-change="axFind" style="min-width: 200px;">
                          </multiselect>
                        </template>
                        <template #cell(offers_premium)="data">
                          <multiselect v-model="bulkData[data.index].offers_premium" label="name" track-by="name"
                            placeholder="Offers Premium" open-direction="bottom" :options="offersSearchOptions"
                            :searchable="true" :loading="isOffersSearch" :close-on-select="true" :multiple="true"
                            style="min-width: 200px;">
                          </multiselect>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </b-overlay>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import XLSX from 'xlsx';
import constant from '../../store/constant';
import { DetailItems } from '../../router/content';
import { SingleEditPath } from '../../router/marketing';

delete constant.ITEM_TYPES.AUDIOBOOK;
delete constant.ITEM_TYPES.NEWSPAPER;
delete constant.CONTENT_TYPE.AUDIO;
const { ROLES } = constant;

export default {
  data() {
    return {
      file_csv: null,
      URL_STATIC: constant.URL_STATIC,
      vendor: null,
      isVendorSearch: false,
      authorNotFound: [],
      csvDataRaw: [],
      bulkData: [],
      itemTypeOptions: Object.values(constant.ITEM_TYPES).map(val => val.toUpperCase()),
      // itemTypeOptions: Object.values(constant.ITEM_TYPES).slice(1).map(val => val.toUpperCase()),
      contentTypeOptions: Object.values(constant.CONTENT_TYPE).map(val => val.toUpperCase()),
      currencyOptions: Object.values(constant.PRINTED_PRICE_ITEM).map(val => val.toUpperCase()),
      fields: [
        { key: "action" },
        {
          key: "name",
          stickyColumn: true,
          label:'Name (Item’s Title)'
        },
        { key: "author" },
        { key: "languages" },
        { key: "countries" },
        { key: "categories" },
        { key: "content_type" },
        { key: "item_type" },
        { key: "release_date" },
        { key: "release_schedule" },
        { key: "description" },
        { key: "isbn" },
        { key: "ios_tier" },
        { key: "android_tier" },
        { key: "printed_currency" },
        { key: "printed_price" },
        { key: "category_ax" },
        { key: "offers_premium" },
      ],
      isValidate: false,
      isUpload: false,
      progressBar: 0,
      maxProgressBar: 100,
      authorSearchOptions: [],
      isAuthorSearch: false,
      languagesSearchOptions: [],
      countriesSearchOptions: [],
      categoriesSearchOptions: [],
      isCategoriesSearch: false,
      axSearchOptions: [],
      isAxSearch: false,
      statusUpload: [],
      debounce: null,
      offersSearchOptions: [],
      isOffersSearch: false,
      iosTierSearchOptions: [],
      isIosTierSearch: false,
      androidTierSearchOptions: [],
      isAndroidTierSearch: false,
      DetailItems,
      SingleEditPath,
    };
  },
  created() {
    this.setCountriesOptions();
    this.languagesSearchOptions = Object.entries(constant.LANGUAGE_CHOICES).map((item) => {
      return {
        iso: item[0].toUpperCase(),
        name: item[1]
      }
    }); 
    this.searchPremiumOffers({ name: '' }).then((response) => {
      this.offersSearchOptions = response.data.data.rows;
    });
    this.fetchVendors({ name:'', status: true });
    this.fetchOwnVendors();
  },
  watch: {
    async file_csv() {
      if (!this.file_csv) {
        this.reset();
        return;
      }
      this.csvToTable();
    },
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.isUpload = false;
      this.messageAlert('success', this.successMessage);
    },
    ownVendor: function(data) {
      this.vendor = data;
    }
  },
  computed: {
    ...mapGetters("auth", { auth: "auth" }),
    ...mapState({
      isError: (state) => state.items.isError,
      isLoading: (state) => state.items.isLoading,
      errorMessage: (state) => state.items.errorMessage,
      successMessage: (state) => state.items.successMessage,
      vendorSearchOptions: (state) => state.vendors.items,
      ownVendor: (state) => state.vendors.item,
    }),
  },
  methods: {
    ...mapActions('vendors', ['fetchVendors', 'fetchOwnVendors']),
    ...mapActions('items', ['bulkCreateUpload', 'getCountries', 'searchCategoriesV2']),
    ...mapActions('ax', ['searchCategoryAx']),
    ...mapActions('author', ['searchAuthor', 'postAuthor']),
    ...mapActions('brands', ['searchBrands']),
    ...mapActions('offerspremium', {
      searchPremiumOffers: 'searchOffers',
    }),
    ...mapActions('tiercodeAndroid', {
      searchAndroidTier: 'searchAndroidTierSpecific',
    }),
    ...mapActions('tiercodeIOS', {
      searchIosTier: "searchIosTierSpecific",
    }),
    async onUpload(event) {
      event.preventDefault();
      this.statusUpload = [];
      if (!this.vendor?.id) {
        this.messageAlert('error', 'vendor not selected!', 5000);
        return;
      }
      if (!this.bulkData.length) {
        this.messageAlert('error', 'Empty Data!', 5000);
        return;
      }

      // validate null object
      for (const [idx, data] of this.bulkData.entries()) {
        const formatData = this.formatData(data);
        delete formatData.offers_premium;
        delete formatData.price_usd;
        const checkData = Object.values(formatData);
        if (checkData.includes(null) || checkData.includes(undefined) || checkData.includes('')) {
          this.messageAlert('error', `please fill in completely in row ${idx + 1}`, 5000);
          return;
        }
      }

      // const releaseSchedule = new Date();
      // releaseSchedule.setTime(releaseSchedule.getTime() + (60*60*1000));
      // prepare data before bulk
      const bulkData = this.bulkData.map(val => {
        const formatData = this.formatData(val);
        formatData.row_id = val.row_id;
        formatData.vendor_id = this.vendor.id;
        formatData.item_type = val.item_type.toLowerCase();
        formatData.content_type = val.content_type.toLowerCase();
        formatData.author_id = val.author?.map(item => item.id);
        formatData.categories_id = val.categories.map(item => item.id);
        formatData.category_ax_id = val.category_ax.id,
        formatData.release_schedule = val.release_schedule;
        formatData.issue_number = val.author?.map(item => item.name).join();
        formatData.countries = val.countries.map(item => item.iso.toLowerCase());
        formatData.languages = val.languages.map(item => item.iso.toLowerCase());
        formatData.offers_premium = (val.offers_premium || []).map(val => val.id);
        formatData.gtin13 = val.isbn;
        return formatData;
      })

      this.$store.commit(`items/setLoading`, true);
      this.isUpload = true;

      // set progress bar
      this.progressBar = 0;
      const progressPerData = this.maxProgressBar / this.bulkData.length;

      for (const payload of bulkData) {
        const result = await this.bulkCreateUpload(payload);
        result.item_name = payload.name;
        this.statusUpload.push(result);
        if (!result.error) {
          this.bulkData = this.bulkData.filter(val => val.row_id != payload.row_id);
        }
        this.progressBar += progressPerData;
      }
      this.isUpload = false;
      this.$store.commit(`items/setLoading`, false);

    },
    reset() {
      this.file_csv = null;
      this.statusUpload = [];
      this.csvDataRaw = [];
      this.bulkData = [];
    },

    boolCheck() {
      return {
        'true': true,
        'yes': true,
        '1': true,
        'false': false,
        'no': false,
        '0': false,
      };
    },

    csvToTable() {
      const reader = new FileReader()
      reader.readAsBinaryString(this.file_csv)
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, {
          type: 'binary',
          cellDates: true,
          raw: true,
          dateNF: 'yyyy-mm-dd hh:mm:ss',
        });
        let csvData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        this.csvDataRaw = csvData;
        this.validateDataCsv();
      }
    },

    async validateDataCsv() {
      this.isValidate = true;
      this.authorNotFound = [];
      this.bulkData = this.csvDataRaw.map(
        (item, idx) => {
          const formatData = this.formatData(item);
          formatData.content_type = item.content_type.toUpperCase();
          formatData.item_type = item.item_type.toUpperCase();
          formatData.row_id = idx;
          return formatData;
        });
      for (const val of this.bulkData) {
        const lang = val.languages?.toLowerCase().trim().split('#');
        if (lang.length) {
          val.languages = this.languagesSearchOptions.filter(lg => lang.includes(lg.name.toLowerCase()))
        }

        const countries = val.countries?.toLowerCase().trim().split('#');
        if (countries.length) {
          val.countries = this.countriesSearchOptions.filter(cn => countries.includes(cn.name.toLowerCase()))
        }

        const authors = val.author?.trim().split('#');
        val.author = [];
        const tempAuthorNotFound = [];
        for (let author of (authors || [])) {
          const query = author.toLowerCase();
          const check = this.authorSearchOptions.find(as => as.name.toLowerCase() == query);
          if (check?.name) {
            val.author.push(check);
            continue;
          }

          // query = encodeURIComponent(query);
          const response = await this.searchAuthor({ name: query });
          // query = decodeURIComponent(query);
          const rows = response.data.data?.rows;
          const findAuthor = rows.find(au => au.name.toLowerCase() == query.toLowerCase());
          if (findAuthor?.name) {
            this.authorSearchOptions.push(findAuthor);
            val.author.push(findAuthor);
          } else {
            tempAuthorNotFound.push(author);
          }
        }
        this.authorNotFound.push(tempAuthorNotFound);

        const categories = val.categories?.toLowerCase().trim().split('#');
        val.categories = [];
        for (let query of (categories || [])) {
          query = query.toLowerCase();
          const check = this.categoriesSearchOptions.find(as => as.name.toLowerCase() == query);
          if (check?.name) {
            val.categories.push(check);
            continue;
          }

          const response = await this.searchCategoriesV2({
            name: query,
            is_active: 1,
            item_type: val.item_type?.toLowerCase(),
          });
          const rows = response.data.data?.rows;
          const findCategory = rows.find(ct => ct.name.toLowerCase() == query)
          if (findCategory?.name) {
            this.categoriesSearchOptions.push(findCategory);
            val.categories.push(findCategory);
          }
        }

        const premiums = val.offers_premium?.toLowerCase().trim().split('#');
        val.offers_premium = [];
        for (let query of (premiums || [])) {
          query = query.toLowerCase();
          const check = this.offersSearchOptions.find(op => op.name.toLowerCase() == query);
          if (check) {
            val.offers_premium.push(check);
          }
        }

        const checkAx = this.axSearchOptions.find(ct => ct.name?.trim().toLowerCase() == val.category_ax.trim().toLowerCase());
        if (checkAx) {
          val.category_ax = checkAx;
        } else {
          let categoryAx = await this.searchCategoryAx({ q: val.category_ax });
          categoryAx = categoryAx.data.data?.rows;
          val.category_ax = categoryAx.find(ct => ct.name.toLowerCase() == val.category_ax.toLowerCase());
          this.axSearchOptions.push(...categoryAx);
        }

        let iosTier = await this.searchIosTier({ q: `.c.usd.${val.price_usd}` });
        let androidTier = await this.searchAndroidTier({ q: `.c.usd.${val.price_usd}` });
        val.ios_tier = iosTier.data?.data;
        val.android_tier = androidTier.data?.data;
      }
      this.isValidate = false;
    },

    vendorFind(query) {
      if (!query) return;

      this.isVendorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchVendors({ name: query, status: true }).then(() => {
          this.isVendorSearch = false;
        }).catch(() => {
          this.isVendorSearch = false;
        });
      }, 600);
    },

    authorFind(query) {
      if (!query) return;

      this.isAuthorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchAuthor(payload)
          .then((response) => {
            this.authorSearchOptions = response.data.data.rows;
            this.isAuthorSearch = false;
          })
          .catch(() => {
            this.isAuthorSearch = false;
          });
      }, 600);
    },

    axFind(query) {
      if (!query) return;

      this.isAxSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchCategoryAx(payload).then((response) => {
          this.axSearchOptions = response.data.data.rows;
          this.isAxSearch = false;
        }).catch(() => {
          this.isAxSearch = false;
        });
      }, 600);
    },

    async onCreateAuthor(authorName) {
      await this.postAuthor({
        name: authorName,
        isActive: true,
      });
      this.validateDataCsv();
    },

    cleanAuthorNotFound() {
      let authors = [];
      this.authorNotFound.forEach(val => {
        authors.push(...val);
      });
      authors = [...new Set(authors)];

      return authors;
    },

    isBulkAuthorShow() {
      const authors = this.cleanAuthorNotFound();
      return !!authors.length;
    },

    async onBulkCreateAuthor() {
      this.isValidate = true;
      const authors = this.cleanAuthorNotFound();

      for (const author of authors) {
        await this.postAuthor({
          name: author,
          isActive: true,
        });
      }

      this.validateDataCsv();
    },

    iosTierFind(query) {
      if (!query) return;

      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query }).then((response) => {
          this.iosTierSearchOptions = [response.data.data];
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },

    androidTierFind(query) {
      if (!query) return;

      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchAndroidTier({ q: query }).then((response) => {
          this.androidTierSearchOptions = [response.data.data];
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },

    setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data.data.rows;
          this.isCountriesSearch = false;
        })
        .catch(() => {
          this.isCountriesSearch = false;
        });
    },

    categoriesFind(query, data) {
      if (!query) return;

      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategoriesV2({
          is_active: 1,
          name: query,
          item_type: data.item_type?.toLowerCase(),
        })
          .then((response) => {
            this.categoriesSearchOptions = response.data.data.rows;
            this.isCategoriesSearch = false;
          })
          .catch(() => {
            this.isCategoriesSearch = false;
          });
      }, 600);
    },

    formatData(data = {}, defaultValue = null) {
      const format = {};
      this.fields.forEach(val => format[val.key] = data[val.key] ?? defaultValue);
      format.price_usd = data.price_usd ?? defaultValue;
      delete format.action;
      return format;
    },

    handleAdd() {
      const newRow = this.formatData();
      newRow.id = this.bulkData.length;
      this.bulkData.push(newRow);
    },

    handleDelete(data) {
      this.bulkData.splice(data.index, 1);
      this.authorNotFound.splice(data.index, 1);
    },

    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },

    permFilterVendor() {
      switch (this.auth.role_id) {
        case ROLES.SUPER_ADMIN:
          return true
        case ROLES.INTERNAL_ADMIN:
          return true
        case ROLES.INTERNAL_DATA_ENTRY:
          return true
        default:
          return false;
      }
    },
  },
  filters: {
    convertSize(value) {
      const size = parseFloat(value);
      return (size / 1000 ** 2).toFixed(3)
    },
    formatPriceId(value = 0) {
      return parseFloat(value).toLocaleString('id');
    }
  }
};
</script>

<style scoped>
</style>